import './styles/App.css';
import nftLogo from './assets/The-Spoopies-NFT.png';
import siteBG from './assets/thespoopies-bg.jpg';
import featureBG from './assets/thespoopies-spiral-bg.jpg';
import casey from './assets/The-Spoopies-Casey-Candy-Corn.png'
import futureGhost from './assets/future-ghost-society.png'
import Amara from './assets/thespoopies-Amara-Andrew.jpg'
import JeffAmara from './assets/jeff-sarris-amara-andrew.jpg'

import NFT1 from './assets/thespoopies-witch.jpg'
import NFT2 from './assets/thespoopies-cat.jpg'
import NFT3 from './assets/thespoopies-skeleton.jpg'
import NFT4 from './assets/thespoopies-pumpkin.jpg'

import Unminted from './assets/unminted-frootling.png';

import immortalsAddresses from './whitelists/immortalsAddresses.json';
import frootFrensAddresses from './whitelists/frootFrensAddresses.json';

import { ethers } from "ethers";

import React, { useEffect, useState } from "react";

import { Rings,MutatingDots } from  'react-loader-spinner'

import nftABI from './utils/TheSpoopies.json';

const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

const publicSaleLive = true;

const publicPrice = 0.0031;

const MAX_SUPPLY = 999;

const CONTRACT_ADDRESS = "0x032bb431fDB3550C8d6CF22235a4C065d983a7be";

const App = () => {

  const [currentAccount, setCurrentAccount] = useState(false);
  const [correctNetwork, setCorrectNetwork] = useState(false);
  const [amountToMint,setAmountToMint] = useState(1);

  const [userLevel,setUserLevel] = useState('public');

  const [mintStatus,setMintStatus] = useState('');
  const [walletStatus,setWalletStatus] = useState('wallet-disconnected');
  const [noticeText,setNoticeText] = useState(false);
  const [noMetamask,setNoMetamask] = useState(false);

  const [ethAddress,setETHAddress] = useState(null);

  const [isSoldOut,setIsSoldOut] = useState(false);

  const [walletTokens, setWalletTokens] = useState(null);
  const [walletTokenCount, setWalletTokenCount] = useState(0);


  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
        setNoMetamask("nometamask");
        setNoticeText("You need to install Metamask! Or, if you're on mobile, open the Metamask app, click browser, and go to spoopies.com");
        return;
      } 
    else {
        //setNoticeText("We have the ethereum object.");
      }

    const accounts = await ethereum.request({ method: 'eth_accounts' });

    if (accounts.length !== 0) {
      const accountCaseSensitive = ethers.utils.getAddress(accounts[0]);

      // Get .eth address
      const provider = new ethers.providers.Web3Provider(ethereum);
      const ethAddress = await provider.lookupAddress(accountCaseSensitive);

      setCurrentAccount(accountCaseSensitive);
      setETHAddress(ethAddress);
      buttonSetUserLevel(accountCaseSensitive);

      setWalletStatus("wallet-connected");
      } 
    else {
      //setNoticeText("No authorized account found");
      }
  }

  const connectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        setNoMetamask("nometamask");
        setNoticeText("You need to install Metamask! Or, if you're on mobile, open the Metamask app, click browser, and go to spoopies.com");
        return;
      }

      setWalletStatus("connecting-wallet");

      const accounts = await ethereum.request({ method: "eth_requestAccounts" });
      const accountCaseSensitive = ethers.utils.getAddress(accounts[0]);

      setCurrentAccount(accountCaseSensitive);
      buttonSetUserLevel(accountCaseSensitive);
      setWalletStatus("wallet-connected");
      setNoticeText(false);

      // Is returning user's wallet already connected?
      //setupEventListener() 
      }
    catch (error) {
      setNoticeText(error.message);
      setWalletStatus("wallet-disconnected");
      setMintStatus("");
      }
    }
  
  // Checks if wallet is connected to the correct network
  const checkCorrectNetwork = async () => {
    
    const { ethereum } = window
    if (!ethereum) {
      setNoMetamask("nometamask");
      setNoticeText("You need to install Metamask! Or, if you're on mobile, open the Metamask app, click browser, and go to spoopies.com");
      return;
    }
    let chainId = await ethereum.request({ method: 'eth_chainId' })
    //console.log('Connected to chain:' + chainId)

    /* if (chainId !== '0x1') { rinkeby */
    /* if (chainId === '0x1') { mainnet */
    if (chainId === '0x1') { // mainnet
    setCorrectNetwork(true);
    } else {
        setCorrectNetwork(false);
        setNoticeText("Connect to Ethereum Mainnet and reload the page!");
    }
  }


  /* Mint Functions */
  const immortalsMint = () => mintSkullKid('immortal',getAddressProof(immortalsAddresses));
  const frootFrensMint = () => mintSkullKid('frootFren',getAddressProof(frootFrensAddresses));
  const publicMint = () => mintSkullKid('public');

  const mintSkullKid = async (_accessLevel,_proof=null) => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
        
        let _price;

        _price = publicPrice;
        
        let _mintTotal = (_price * 1000000 * amountToMint / 1000000).toString();
        const _options = {value: ethers.utils.parseEther(_mintTotal)}

        //console.log(_proof);
        //setNoticeText("Opening wallet...");

        setMintStatus("minting");

        let nftTxn;

        nftTxn = await connectedContract.publicMint(amountToMint,_options);
        
        setNoticeText("Waiting for transaction confirmation...")
        await nftTxn.wait();
        
        setMintStatus("minted");
        setNoticeText(`Success!<br />View your Spoopies on <a href="https://opensea.io/account" rel="noreferrer" target="_blank">Opensea</a>!`)

        getMints(true);

      } else {
        setNoticeText("Ethereum object doesn't exist!");
      }
    } catch (error) {
      //console.log(error);
      if (error.code === 'INSUFFICIENT_FUNDS') {
        setNoticeText('Insufficient funds to complete this transaction!')
        setMintStatus("error");
        }
      else if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
        setNoticeText(`Whoops, the mint hasn't started yet!`);
        setMintStatus("error");
      }
      else {
        setNoticeText(error.message);
        setMintStatus("error");
      }
    }
  }

  const setPayoutAddresses = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
        
        //setNoticeText("Opening wallet...")

        let nftTxn = await connectedContract.setPayoutAddresses('0x0BaAd4A35D8BFBD806A0D2829Eed25a12905b3b7','0x0BaAd4A35D8BFBD806A0D2829Eed25a12905b3b7');
        
        setNoticeText("Waiting for transaction confirmation...")
        await nftTxn.wait();
        /*console.log(nftTxn);
        console.log(nftTxn.events);*/
        setNoticeText(`View your transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}`);

      } else {
        setNoticeText("Ethereum object doesn't exist!");
      }
    } catch (error) {
      //console.log(error);
      setNoticeText(error.message);
      }
  };

  const setBaseURI = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
        
        //setNoticeText("Opening wallet...")

        let nftTxn = await connectedContract.setBaseURI('https://api.ryps.co/frootlings/id/');
        
        setNoticeText("Waiting for transaction confirmation...")
        await nftTxn.wait();
        /*console.log(nftTxn);
        console.log(nftTxn.events);*/
        setNoticeText(`View your transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}`);

      } else {
        setNoticeText("Ethereum object doesn't exist!");
      }
    } catch (error) {
      //console.log(error);
      setNoticeText(error.message);
      }
  };

  const getBaseURI = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
        
        const baseURI = await connectedContract.baseURI();

        setNoticeText(`Base URI: ${baseURI}`);

      }
      else {
        setNoticeText("Ethereum object doesn't exist!");
      }
    } 
    catch (error) {
      setNoticeText(error.message);
    }
  }

  const withdrawFromContract = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
        
        //setNoticeText("Opening wallet...")

        let nftTxn = await connectedContract.withdraw();
        
        setNoticeText("Waiting for transaction confirmation...")
        await nftTxn.wait();
        /*console.log(nftTxn);
        console.log(nftTxn.events);*/
        setNoticeText(`All funds withdrawn! https://rinkeby.etherscan.io/tx/${nftTxn.hash}`);

      } else {
        setNoticeText("Ethereum object doesn't exist!");
      }
    } catch (error) {
      //console.log(error);
      setNoticeText(error.message);
      }
  };
  /* END Mint Functions */



  /* Set Functions */
  // Passing _currentWallet to allow calling before global currentAccount is set
  const buttonSetUserLevel = (_currentWallet = currentAccount) => {
    if (getAddressProof(immortalsAddresses,_currentWallet).length !== 0) {
      setUserLevel('immortal');
    }
    else if (getAddressProof(frootFrensAddresses,_currentWallet).length !== 0) {
      setUserLevel('frootFren');
    }
    else {
      setUserLevel('public');
    }
  };
  /* END Set Functions */


  /* Get Functions */
  const getCleanUserLevel = (_userLevel = userLevel) => {
    if (_userLevel === 'god') {
      return "Immortal God";
    }
    else if (_userLevel === 'immortal') {
      return "Immortal";
    }
    else if (_userLevel === 'frootFren') {
      return "Froot Fren";
    }
    else {
      return "Public";
    }
  };
  


  /*
  ** Admin Functions
  */
  const makeSaleLive = async (saleLevel) => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
        
        let nftTxn = "";

        setNoticeText(`Making ${saleLevel} sale live`);

        if (saleLevel === 'Immortals') {
          nftTxn = await connectedContract.toggleImmortalsSale();
        }
        else if (saleLevel === 'Froot Frens') {
          nftTxn = await connectedContract.toggleFrootFrensSale(true);
        }
        else if (saleLevel === 'Public') {
          nftTxn = await connectedContract.togglePublicSale(true);
        }

        setNoticeText("Waiting for transaction confirmation...");
        await nftTxn.wait();
        /*console.log(nftTxn);
        console.log(nftTxn.events);*/
        setNoticeText(`See transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}`);

      }
      else {
        setNoticeText("Ethereum object doesn't exist!");
      }
    } 
    catch (error) {
      setNoticeText(error.message);
    }
  }

  const getTotalSupply = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
        
        const _latestMint = parseInt(await connectedContract.totalSupply()) + 200;

        setNoticeText(`Total Supply: ${_latestMint}`);

      }
      else {
        setNoticeText("Ethereum object doesn't exist!");
      }
    } 
    catch (error) {
      setNoticeText(error.message);
    }
  }
  /* END Admin Functions */



  /* Merkle Functions */

  const makeImmortalsSaleLive = () => makeSaleLive('Immortals');
  const makeFrootFrensSaleLive = () => makeSaleLive('Froot Frens');
  const makePublicSaleLive = () => makeSaleLive('Public');


  //const setGodsMerkleRoot = () => setMerkleRoot('Gods');
  const setImmortalsMerkleRoot = () => setMerkleRoot('Immortals');
  const setFrootFrensMerkleRoot = () => setMerkleRoot('Froot Frens');

  const setMerkleRoot = async (saleLevel) => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
        
        let nftTxn = "";

        setNoticeText(`Setting ${saleLevel} Root Hash...`);

        /*if (saleLevel === 'Gods') {
          nftTxn = await connectedContract.setGodsMerkleRoot(getRootHash('Gods',godsAddresses));
        }
        else*/ if (saleLevel === 'Immortals') {
          nftTxn = await connectedContract.setImmortalsMerkleRoot(getRootHash('Immortals',immortalsAddresses));
        }
        else if (saleLevel === 'Froot Frens') {
          nftTxn = await connectedContract.setFrootFrensMerkleRoot(getRootHash('Froot Frens',frootFrensAddresses));
        }
        
        setNoticeText("Waiting for confirmation...")
        await nftTxn.wait();
        /*console.log(nftTxn);
        console.log(nftTxn.events);*/
        setNoticeText(`${saleLevel} Root Hash Set! https://rinkeby.etherscan.io/tx/${nftTxn.hash}`);

      }
      else {
        setNoticeText("Ethereum object doesn't exist!");
      }
    } 
    catch (error) {
      setNoticeText(error.message);
    }
  }

  window.setMerkleRoot = setMerkleRoot;

    const getRootHash = (listName,vipAddresses) => {    
      const vipMerkleTree = getVIPMerkleTree(vipAddresses);
      const vipRootHash = vipMerkleTree.getRoot().toString('hex');
      setNoticeText(`${listName} Root Hash: 0x${vipRootHash}`);
      return `0x${vipRootHash}`;
    };

  //const getGodsRootHash = () => getRootHash('Immortal Gods',godsAddresses);
  const getImmortalsRootHash = () => getRootHash('Immortals',immortalsAddresses);
  const getFrootFrensRootHash = () => getRootHash('Froot Frens',frootFrensAddresses);
  
  //const getAddressProofGods = () => {console.log("Immortal Gods Proof:");getAddressProof(godsAddresses)};
  const getAddressProofImmortals = () => {console.log("Immortals Proof:");getAddressProof(immortalsAddresses)};
  const getAddressProofFrootFrens = () => {console.log("Froot Frens Proof:");getAddressProof(frootFrensAddresses)};

  //const getREMIXAddressProofImmortals = () => {console.log("REMIX Immortals Proof:");getAddressProof(godsAddresses,"0x5B38Da6a701c568545dCfcB03FcB875f56beddC4")};

  const getVIPMerkleTree = (vipAddresses) => {
    const hashedVIPAddresses = vipAddresses.map(addr => keccak256(addr));
    return new MerkleTree(hashedVIPAddresses, keccak256, { sortPairs: true });
  };

  // Passing currentAccount to allow calls before global set
  const getAddressProof = (vipAddresses,_currentWallet = currentAccount) => {
    const vipMerkleTree = getVIPMerkleTree(vipAddresses);

    const hashedAddress = keccak256(_currentWallet);
    const proof = vipMerkleTree.getHexProof(hashedAddress);
    //console.log(JSON.stringify(proof));

    return proof;
  };
  /* END Merkle Functions */


  // Display connected wallet whenever returning to site
  /*useEffect(() => {
    checkIfWalletIsConnected();
    checkCorrectNetwork();
  })*/


  /* Render Admin */
  const renderAdminButtons = (
    <div className="admin-buttons">
      <h2>Admin Functions</h2>
      <h4>All Mint Buttons</h4>
      <p>
        <button onClick={publicMint} className="cta-button connect-wallet-button">Public Mint {publicPrice}</button>
        </p>
      <h4>Root Hashes</h4>
      <p>
        {/*<button onClick={getGodsRootHash} className="cta-button connect-wallet-button">Get Gods Root Hash</button> */}
        <button onClick={getImmortalsRootHash} className="cta-button connect-wallet-button">Get Immortals Root Hash</button>
        <button onClick={getFrootFrensRootHash} className="cta-button connect-wallet-button">Get Froot Frens Root Hash</button></p>
      <h4>Proofs</h4>
      <p>
        {/*<button onClick={getAddressProofGods} className="cta-button connect-wallet-button">My Immortal Gods Proof</button>*/}
        <button onClick={getAddressProofImmortals} className="cta-button connect-wallet-button">My Immortals Proof</button>
        <button onClick={getAddressProofFrootFrens} className="cta-button connect-wallet-button">My Froot Frens Proof</button>
        {/*<button onClick={getREMIXAddressProofImmortals} className="cta-button connect-wallet-button">My REMIX Immortals Proof</button>*/}
        </p>
      <h4>Make Sales Live</h4>
      <p>
        <button onClick={makeImmortalsSaleLive} className="cta-button connect-wallet-button">Make Immortals Sale Live</button>
        <button onClick={makeFrootFrensSaleLive} className="cta-button connect-wallet-button">Make Froot Frens Sale Live</button>
        <button onClick={makePublicSaleLive} className="cta-button connect-wallet-button">Make Public Sale Live</button>
        </p>
      <h4>Set Merkle Roots</h4>
      <p>
        {/*<button onClick={setGodsMerkleRoot} className="cta-button connect-wallet-button">Set Immortal Gods Merkle Root</button>*/}
        <button onClick={setImmortalsMerkleRoot} className="cta-button connect-wallet-button">Set Immortals Merkle Root</button>
        <button onClick={setFrootFrensMerkleRoot} className="cta-button connect-wallet-button">Set Froot Frens Merkle Root</button>
        </p>
      <h4>Payouts</h4>
      <p>
        <button onClick={setPayoutAddresses} className="cta-button connect-wallet-button">Set Payout Addresses</button>
        <button onClick={withdrawFromContract} className="cta-button connect-wallet-button">Withdraw All Funds</button>
        </p>
      <p>
        <button onClick={buttonSetUserLevel} className="cta-button connect-wallet-button">Set User Level</button>
        <button onClick={setBaseURI} className="cta-button connect-wallet-button">Set Base URI</button>
        <button onClick={getBaseURI} className="cta-button connect-wallet-button">Get Base URI</button>
        <button onClick={getTotalSupply} className="cta-button connect-wallet-button">Get totalSupply()</button>
      </p>
      </div>
  );  
  /* END Render Admin */



  /* Render Frontend */
  const renderSalesInactive = () => (
    <>
      <div className="entrance">
        <div className={"mint-columns " + userLevel}>
          <div className="column-price column">
            <div className="mintPrice">
              <div className="userLevelMintPrice">
                Price
                <div className="userLevelMintPriceWrap">{publicPrice}</div>
              </div>
            </div>
          </div>
        </div>
        <button disabled>Minting 10/01/22!</button>
        </div>
    </>
  );
  
  
  const renderWrongNetwork = (
    <div className="loading-wrong-network">
      <div className="loading-wrong-network-rings">
        <Rings color="#ffffff" height={120} width={120} /><br />
      </div>
      <div className="loading-wrong-network-text">{noticeText}</div>
    </div>
  );
  
  /*const renderNotConnectedContainer = () => (
    <>
      <div className="entrance">
        <div className="loading"><MutatingDots color="#ffffff" secondaryColor="#ffffff" height={120} width={120} /></div>
        {renderWrongNetwork}
        <div className={"mint-columns " + userLevel}>
          <div className="column-price column">
            <div className="mintPrice">
              <div className="userLevelMintPrice">
                Price
                <div className="userLevelMintPriceWrap">{publicPrice}</div>
                {publicSaleLive === true ? 'Sale Open' : 'Opening Soon'}
              </div>
            </div>
          </div>
        </div>
        <div className="notice">{(noticeText !== false ? <div className="notice-text" dangerouslySetInnerHTML={{__html: noticeText}}></div> : "")}</div>
        <button onClick={connectWallet} className="cta-button connect-wallet-button">Connect Wallet</button>
        </div>
    </>
  );*/

  const renderNotConnectedButton = () => (
    <>
    <div className="entrance">
    {renderWrongNetwork}
    <div className="loading"><MutatingDots color="#ffffff" secondaryColor="#ffffff" height={120} width={120} /></div>
    <button onClick={connectWallet} className="cta-button connect-wallet-button">Connect Wallet</button>
    </div>
    </>
  );

  /*const renderSoldOutContainer = () => (
    <>
      <div className="sold-out">
        Sold Out!
      </div>
    </>
  );*/

  const renderConnectedButton = () => (
    <><button className="cta-button connect-wallet-button logged-in">{renderShortAddress()}</button></>
  );

  const renderNotConnectedNav = () => (
    <><button onClick={connectWallet} className="cta-button connect-wallet-button">Connect Wallet</button></>
  );

  const renderMintCountSelect = (
    <div className="mintCountWrapper">
      <select name="mintCount" id="mintCount" className="mintCount" onChange={event => setAmountToMint(event.target.value)}>
        <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="7">7</option><option value="8">8</option><option value="9">9</option><option value="10">10</option>
        <option value="11">11</option><option value="12">12</option><option value="13">13</option><option value="14">14</option><option value="15">15</option><option value="16">16</option><option value="17">17</option><option value="18">18</option><option value="19">19</option>
        <option value="21">21</option><option value="22">22</option><option value="23">23</option><option value="24">24</option><option value="25">25</option>
        </select>
      </div>
  );

  const renderTotal = () => {
    return publicPrice * 1000000 * amountToMint / 1000000;
  };

  /*const getUserLevelPrice = (_userLevel = userLevel) => {
    return publicPrice;
  };*/

  const renderMintButton = (
    (userLevel === 'immortal' ?
        <button onClick={immortalsMint} className="cta-button connect-wallet-button">Mint Now!</button>
        : (userLevel === 'frootFren' ?
          <button onClick={frootFrensMint} className="cta-button connect-wallet-button">Mint Now!</button>
          : <button onClick={publicMint} className="cta-button connect-wallet-button">Mint Now!</button>
          )
        )
  );

  const renderMintButtons = (
    <div className="mintWrapper">
      <div className="loading"><MutatingDots color="#ffffff" secondaryColor="#ffffff" height={120} width={120} /></div>
      {
        (
          publicSaleLive ? 
          <>
          <div className={"mint-columns " + userLevel}>
            <div className="column-select column">{renderMintCountSelect}</div>
            <div className="column-total column">{renderTotal()}</div>
          </div>
          <div className="notice">{(noticeText !== false ? <div className="notice-text" dangerouslySetInnerHTML={{__html: noticeText}}></div> : "")}</div>
          {renderMintButton}
          </> : 
            <div className="entrance">
            <div className="loading"><MutatingDots color="#ffffff" secondaryColor="#ffffff" height={120} width={120} /></div>
            {renderWrongNetwork}
            <div className={"mint-columns " + userLevel}>
              <div className="column-price column">
                <div className="mintPrice">
                  <div className="userLevelMintPrice">
                    Froot Frens Price
                    <div className="userLevelMintPriceWrap">{publicPrice}</div>
                    {publicSaleLive === true ? 'Sale Open' : 'Opening Soon'}
                  </div>
                </div>
              </div>
            </div>
            <div className="notice">{(noticeText !== false ? <div className="notice-text" dangerouslySetInnerHTML={{__html: noticeText}}></div> : "")}</div>
            <div className="saleClosed">The {getCleanUserLevel()} sale isn't open yet!{userLevel === 'public' ? <><br />Get on the Froot Frens Allowlist! <a href="https://twitter.com/jeffSARRIS/status/1520120640023453698?s=20&t=5bEdtmuMyFeVU-31gf_SOA" target="_blank" rel="noreferrer">Details Here</a></> : <></>}</div>
            </div>
        )
      }
      </div>
  );

  const renderMintUI = () => (
    <>
    <div className="entrance">
    {renderWrongNetwork}
    {renderMintButtons}
    </div>
    </>
  );


  const checkIfSoldOut = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
        
        var totalMinted = await connectedContract.totalSupply();

        if (parseInt(totalMinted.toNumber()) >= MAX_SUPPLY) { setIsSoldOut(true); }
        
      }
      else {
        console.log("Ethereum object doesn't exist!");
      }
    } 
    catch (error) {
      console.log(error.message);
    }
  }

  const getMints = async (forceReload = false) => {

    if (currentAccount && (walletTokens === null || forceReload)) {
      try {
        const { ethereum } = window;

        if (ethereum) {
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
          
          const skullkidsTokens = await connectedContract.tokensOfOwner(currentAccount);

          // Amara
          //const skullkidsTokens = await connectedContract.tokensOfOwner('0x355E64DD4bEB2DdC8136b1C17bf18611320AC5B2');

          // Froot Stand
          //const skullkidsTokens = await connectedContract.tokensOfOwner('0x91c1A7cc16dB55F861559EC515eb965c03A9Bd1A');

          // Dellyjoe
          //const skullkidsTokens = await connectedContract.tokensOfOwner('0xC01eBC09ec7D2432a97B0094F84EeC2029ED8169');

          // Luis
          //const skullkidsTokens = await connectedContract.tokensOfOwner('0xB74D382a9aAc99a33b1129A234ABe3938bA6dECb');

          // ZombieBits
          //const skullkidsTokens = await connectedContract.tokensOfOwner('0xcfc80e71d63ef4b30dc38507196616ef625dfdf5');

          // Madkat
          //const skullkidsTokens = await connectedContract.tokensOfOwner('0x546af69a6d39d93126ef36beb006a824b25a60c6');

          // EquinsuOcha
          //const skullkidsTokens = await connectedContract.tokensOfOwner('0x913D5f297095b3E32d1AFF25D0f7c6F3E8D61d89');
          
          //if (skullkidsTokens.length > 0) {
            setWalletTokenCount(skullkidsTokens.length);

            var nftHTML = '';
            var count = 0;
            var prefix = 'Frootling';
            var levelClass = '';
            var tokensPerPage = 28;

            // Display Images Loop
            for (let i = skullkidsTokens.length - 1;i >= 0; i--) {
              count++;
              levelClass = '';

              if (count === 0) { levelClass = "None"; }
              else if (count === 1) { levelClass = "SkullKid"; }
              else if (count === 5) { levelClass = "Phenom"; }
              else if (count === 20) { levelClass = "Hero"; }
              else if (count === 50) { levelClass = "Icon"; }
              else if (count === 100) { levelClass = "Legend"; }
              else if (count === 500) { levelClass = "Mythic"; }

              let tokenMetadataURI = 'https://api.ryps.co/frootlings/id/' + skullkidsTokens[i].toNumber();

              const tokenMetadata = await fetch(tokenMetadataURI).then((response) => response.json());

              nftHTML += `<div className="nft ${levelClass}">`;
              nftHTML += `<a href="https://opensea.io/assets/${CONTRACT_ADDRESS}/${skullkidsTokens[i].toNumber()}" className="anchor" target="_blank" rel="noreferrer">`;
              nftHTML += `<img src="https://api.ryps.co/frootlings/images_600/${tokenMetadata['dna']}.png" alt=${tokenMetadata['name']} />`;
              nftHTML += `<div className="nft-title"><span>${prefix}</span> #${skullkidsTokens[i].toNumber()}</div>`;
              nftHTML += '</a>';
              nftHTML += '</div>';

              if (count >= tokensPerPage) { break; }
            }

            let j = 0;
            var fillCount = getWalletLevel('next-count',skullkidsTokens.length) > 1 ? getWalletLevel('next-count',skullkidsTokens.length) : 4;

            while (count < tokensPerPage && j < fillCount) {
              count++;
              j++;
              levelClass = '';

              if (count === 1) { levelClass="SkullKid"; }
              else if (count === 5) { levelClass="Phenom"; }
              else if (count === 20) { levelClass="Hero"; }
              else if (count === 50) { levelClass="Icon"; }
              else if (count === 100) { levelClass = "Legend"; }
              else if (count === 500) { levelClass = "Mythic"; }
              
              nftHTML += `<div className="nft  ${levelClass} unminted">`;
                nftHTML += '<div className="anchor">';
                  nftHTML += `<img src=${Unminted} alt="SkullKid" />`;
                  nftHTML += `<div className="nft-title"><span>${prefix}</span> #----</div>`;
                nftHTML += '</div>';
              nftHTML += '</div>';
            }

            if (skullkidsTokens.length > 0) {
              nftHTML += '<div className="view-more"><a href="https://opensea.io/account?search[resultModel]=ASSETS&search[sortBy]=CREATED_DATE&search[query]=skullkid&search[sortAscending]=false" target="_blank" rel="noreferrer">';
              nftHTML += 'View on OpenSea';
              nftHTML += '</a></div>';
            }

            setWalletTokens(nftHTML);
        }
        else {
          console.log("Ethereum object doesn't exist!");
        }
      } 
      catch (error) {
        console.log(error.message);
      }
    }
  };

  const awaitGetMints = async () => {
    await getMints();
  };

  useEffect(() => {
    checkIfWalletIsConnected();
    checkCorrectNetwork();
    checkIfSoldOut();
    awaitGetMints();
  });

  const renderShortAddress = () => {
    if (ethAddress !== null) {
      return ethAddress;
    }
    else {
      return currentAccount.toString().substr(0,6) + '\u2026' + currentAccount.toString().substr(-4)
    }
  };

  const getWalletLevel = (getLevel = 'current',_walletTokenCount = walletTokenCount) => { // Pass walletTokenCount when state hasn't been set
    var _level = '';

    var levelNames = [
      [ 500,"SkullKid Mythic" ],
      [ 100,"SkullKid Legend" ],
      [ 50,"SkullKid Icon" ],
      [ 20,"SkullKid Hero" ],
      [ 5,"SkullKid Phenom" ],
      [ 1,"SkullKid" ],
      [ 0,"None" ],
    ];

    for (let i = 0; i < levelNames.length; i++) {
      if (_walletTokenCount >= levelNames[i][0]) {
        if (getLevel === 'current') {
          _level = levelNames[i][1];
        }
        else if (getLevel === 'next') {
          if (i > 0) {
            let nextLevelIndex = i-1;
            _level = `${levelNames[nextLevelIndex][0]-_walletTokenCount} away from ${levelNames[nextLevelIndex][1]}`;
          }
          else {
            _level = false;// No higher level
          }
        }
        else if (getLevel === 'next-count') {
          if (i > 0) {
            let nextLevelIndex = i-1;
            _level = `${levelNames[nextLevelIndex][0]-_walletTokenCount}`;
          }
          else {
            _level = false;// No higher level
          }
        }
        return _level;
      }
    }
  };


  /*const showWalletTokens = (
    <div className="wallet-token-wrapper" id="collection">
      <div className="wallet-token-header">
        <h2><small>Your</small> Frootlings <small className="bottom-small">Collection</small></h2>
        <div className="wallet-details">
          <div className="wallet-address"><strong>Wallet:</strong> {renderShortAddress()}</div>
          <div className="wallet-status"><strong>Status:</strong> {(getCleanUserLevel() !== 'Public' ? getCleanUserLevel() : 'Froot Fren')}</div>
        </div>
      </div>
      <div className="wallet-quantity">{walletTokenCount !== 1 ? walletTokenCount + ' Spoopies' : 'Spoopie'}</div>
      <div className="wallet-tokens" dangerouslySetInnerHTML={{__html: walletTokens}}></div>
    </div>
  );*/


  return (
    <div className={`App ${walletStatus} ${noMetamask} ${mintStatus}${correctNetwork === false ? ' wrong-network' : publicSaleLive !== true ? ' countdown' : ''}`} style={{ backgroundImage: `url(${siteBG})` }}>
      <div className="site-header">
        <div className="wrap">
          <div className="header-left">
            <a href="#story">Story</a> <a href="#amara">Artist</a> <a href="#faq">FAQ</a>
          </div>
          <div className="header-right">
            {correctNetwork === false ? '' : currentAccount === false ? renderNotConnectedNav() : renderConnectedButton()}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="header-container" style={{ backgroundImage: `url(${featureBG})` }}>
          <div className="large-logo" id="mint">
            <div className="presents">Amara Andrew Presents</div>
            <img src={nftLogo} alt="The Spoopies" title="The Spoopies" />
          </div>
          {isSoldOut === true ? null : publicSaleLive === true ? currentAccount === false ? renderNotConnectedButton() : renderMintUI() : renderSalesInactive()}
          <div className="column-wrap header-footer">
            <div className="column first">
              &copy; Copyright MMXXII
            </div>
            <div className="column">
              A Future Ghost Society Production
            </div>
            <div className="column last">
              All Rights Reserved
            </div>
          </div>
          <img src={casey} className="floating-character" alt="The Spoopies - Casey" />
        </div>
        <div className="content-section content-section-1 content-black-bg" id="future-ghost">
        {/* Minted Tokens currentAccount !== false ? showWalletTokens : undefined*/}
          <div className="wrap">
            <div className="content-image-column-wrap">
              <div className="content-image-column">
                <img src={futureGhost} alt="Future Ghost Society" className="future-ghost" width="455" height="425" />
              </div>
              <div className="content-image-column">
                <h3 className="has-small">
                  <small>Welcome to the</small>
                  Future Ghost Society
                </h3>
                <p>Welcome to the <a href="https://futureghost.co" rel="noreferrer" target="_blank">Future Ghost Society</a>, an underground web3 social club where your NFT is your membership pass to a community of people who are here to make the most of their pre-ghost time.</p>
                <p>If you don't belong anywhere, you'll belong here.</p>
                <p>Owning any NFT from The Spoopies, <a href="https://opensea.io/collection/the-dours" rel="noreferrer" target="_blank">The Dours</a>, or <a href="https://opensea.io/collection/byamara" rel="noreferrer" target="_blank">Amara's Odds &amp; Ends</a> grants you exclusive access to the FGS.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-section content-section-2" id="story">
          <div className="wrap">
            <h2 className="has-small">
              <small>Meet</small>
              The Spoopies
            </h2>
            <div className="columns-4">
              <img src={NFT1} alt="The Spoopies" />
              <img src={NFT2} alt="The Spoopies" />
              <img src={NFT3} alt="The Spoopies" />
              <img src={NFT4} alt="The Spoopies" />
            </div>
            <div className="sidebar-content">
              <div className="sidebar">
                <ul>
                  <li>999 Supply</li>
                  <li>No Roadmap</li>
                  <li>No Discord</li>
                  <li>Instant Reveal</li>
                  <li>Future Ghost Society Membership</li>
                </ul>
              </div>
              <div>
                <p>It was a dark and stormy night when the rusty gray gates to Candy Corn City slowly creaked open. No one had ever seen its citizens…that is, until October 1st. As the Spoopies slowly emerge from the safe, gray confines of their beloved city, only time will tell how the Spoopies choose to navigate the human world. Be warned, though, some of them are quite mischievous.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-section content-section-3 content-black-bg" id="amara">
        {/* Minted Tokens currentAccount !== false ? showWalletTokens : undefined*/}
          <div className="wrap">
            <div className="image-content-column-wrap">
              <div className="image-content-column">
                <img src={Amara} alt="Amara Andrew" width="660" height="660" />
              </div>
              <div className="image-content-column">
                <h3 className="has-small">
                  <small>Meet the Artist</small>
                  Amara Andrew
                </h3>
                <p>If I don't see you with this look on your face when you're picking out a pumpkin, I have one question: <em>Do you even like Halloween?!</em></p>
                <p>Hi, I'm <a href="https://amaraandrew.com" rel="noreferrer" target="_blank">Amara</a>! I may not look like it, but I've always LOVED Halloween. There's something so oddly comforting about a good scare and spoopy things.</p>
                <p>As an art historian, I'm a massive fan of the early years of animation, particularly that of the 1920s and early 1930s. The fact that people were able to figure out how to create moving images will never cease to blow my mind!!</p>
                <p>The combination of these two loves naturally lent itself to the creation of The Spoopies!</p>
                <p>Oh, and you can check out my main NFT collection, <a href="https://opensea.io/collection/the-dours" rel="noreferrer" target="_blank">The Dours</a>, for year-round spooky fun.</p>
                <p>Anywho, that's enough about me. Tell me about you! Tweet at me <a href="https://twitter.com/AmaraAndrew" rel="noreferrer" target="_blank">@AmaraAndrew</a>.</p>
                <p>Stay Spoopy, frens! ♡</p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-section content-section-3" id="faq">
          <div className="wrap">
            <h2 className="has-small">
              FAQ
            </h2>
            <dl>
              <dt>Who are The Spoopies?</dt>
              <dd>The Spoopies are a collection of 999 hand-drawn characters inspired by animation from the 1920s and early 1930s. The Spoopies inhabit Candy Corn City, a city filled with candy corn, witches, skeletons, pumpkins, black cats, and ghosts. Be warned, though, some of them are quite mischievous.</dd>
              <dt>Is there a Discord?</dt>
              <dd>Nope! It's just me (Amara Andrew) and Jeff Sarris runnin' Future Ghost Society. In lieu of a Discord, you can join The Spoopies community on Twitter <a href="https://twitter.com/TheSpoopies" rel="noreferrer" target="_blank">@TheSpoopies</a>. Besides, aren't you part of enough Discords, already?!</dd>
              <dt>Where can I buy a Spoopy?</dt>
              <dd>Right up above! If you've never minted an NFT before and have any questions, just tag me (<a href="https://twitter.com/AmaraAndrew" rel="noreferrer" target="_blank">@AmaraAndrew</a>) on Twitter!</dd>
              <dt>Where's your roadmap?</dt>
              <dd>Foolish human, roadmaps are not spoopy.</dd>
              <dt>What's the utility?</dt>
              <dd>Your Spoopy grants you exclusive access into the underground web3 social club, <a href="https://futureghost.co" rel="noreferrer" target="_blank">Future Ghost Society</a>. Doors opening soon...</dd>
              <dt><img src={JeffAmara} alt="Jeff Sarris &amp; Amara Andrew" width="800" height="800" className="floatright" />Who's on the team?</dt>
              <dd>
                <p>Just me (Amara Andrew) and Jeff Sarris! I'm the artist and Jeff is the <a href="https://ryps.co" rel="noreferrer" target="_blank">mad scientist developer</a>. You can find us on Twitter at <a href="https://twitter.com/amaraandrew" rel="noreferrer" target="_blank">@AmaraAndrew</a> and <a href="https://twitter.com/jeffsarris" rel="noreferrer" target="_blank">@jeffSARRIS</a>!</p>
              </dd>
            </dl>
          </div>
        </div>
        <div className="site-footer content-black-bg">
          <div className="wrap">
            &copy;{new Date().getFullYear()} <a href="https://amaraandrew.com" rel="noreferrer" target="_blank">Amara Andrew</a><br />
            NFT Development by <a href="https://ryps.co" rel="noreferrer" target="_blank">RYPS</a>
          </div>
        </div>
         {/* Admin */}
         {false ? renderAdminButtons : undefined}
      </div>
    </div>
  );
};

export default App;